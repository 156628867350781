import { Link } from "react-scroll";
import React from "react";
import nav from "../../data/nav.json";
import social from "../../data/social.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

// This exports the whole icon packs for Brand and Solid.
library.add(fab, fas)
function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-link-container">
        <div className="footerLogo">
          <a href="/" className="linkHome">
            <img src="./img/cropped-logo-rage.png" alt="Bekky Rage" />
          </a>
        </div>
        <div className="footer-items">
          <ul>
            {nav?.map((item, index) =>
              <li key={index}>
                <Link
                  activeClass="navbar-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to={item.path}
                  className="text-md"
                >
                  {item.page}
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="footer-social-icon">
          <ul>
            {social?.map((item, index) =>
              <li key={index}>
                <a
                  href={item.href}
                  className="navbar-content"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', item.icon]} />
                </a>
              </li>
            )}
            <li>
              <a
                href="mailto:bekkyrage@gmail.com"
                className="navbar-content"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faSquareEnvelope} />
              </a>
            </li>
          </ul>
        </div>
      </div>


      <hr className="divider" />
      <div className="footer-content-container">
        <p className="footer-content">
          Copyright © {(new Date().getFullYear())} Bekky Rage. All rights reserved.
        </p>
        <p className="footer-content">
          <FontAwesomeIcon icon={faCoffee} /> Powered by Caffeine Drinks.
        </p>
      </div>
    </footer>
  );
}

export default Footer;