import SubNavbar from "../global/subNavBar";
import Inner404 from "../global/inner404";
import SubFooter from "../global/subFooter";


export default function _404 () {
  return(
    <>
      <SubNavbar />
      <Inner404 />
      <SubFooter />
    </>
  )
}