import ContactForm from "../../scripts/components/contactForm";

export default function Contact() {
  return (

    <section id="Contact" className="contact-section">
      <div className="contact-header">
        <p className="sub-title">Get In Touch</p>
        <h2>Contact Me</h2>
        <p className="text-lg">
          I'd love to hear from you!
        </p>
      </div>
      <ContactForm />
    </section>
  )
}