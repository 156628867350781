import { useState, useReducer, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha';

const initialState = {
  name: '',
  email: '',
  message: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.value };
    case 'email':
      return { ...state, email: action.value };
    case 'message':
      return { ...state, message: action.value };
    default:
      throw new Error();
  }
}

export default function ContactForm() {
  const form = useRef();
  const [formState, dispatch] = useReducer(reducer, initialState);
  const [showFormErr, setShowFormErr] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState({ title: '', paragraph: '' });
  const [showCaptcha, setShowCaptcha] = useState(false);
  const { name, email, message } = formState;

  const submitFormAndShowCaptcha = (e) => {
    e.preventDefault();
    setShowCaptcha(true);
  };

  const sendEmail = (captchaValue) => {
    if (name === '' || email === '' || message === '') {
      setShowFormErr(true);
      return;
    }

    const params = {
      ...formState,
      'g-recaptcha-response': captchaValue,
    };

    setFormSubmitted({ title: 'Sending message...', paragraph: '' });
    emailjs.send(
      'service_t2hfolr',
      'template_xtrckpq',
      params,
      'h3K6Qxm-JVtZfUFfW',
    )
      .then(({ status }) => {
        if (status === 200) {
          setFormSubmitted({ title: 'Message Sent.', paragraph: 'Bekky will be in contact with you soon.' });
        } else {
          setFormSubmitted({ title: 'Unexpected Status.', paragraph: 'Sorry there was an error. Please try again later.' });
        }
      }, (err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setFormSubmitted({ title: 'Error Sending Message.', paragraph: 'Error sending message. Please try again later.' });
      });
  };

  return formSubmitted.title === '' ? (
    <div className="contactformWrap">
      {!showCaptcha ? (
        <form className="contact-form-container" ref={form} onSubmit={submitFormAndShowCaptcha}>


          <Container>
            <Row>
              <label className="contact-label" htmlFor="contact-form-name">
                <span className="text-md">Name</span>
                <input
                  id="contact-form-name"
                  className="contact-input text-md"
                  type="text"
                  name="user_name"
                  value={name}
                  onChange={(e) => dispatch({ type: 'name', value: e.target.value })}
                  required
                />
              </label>
            </Row>
            <Row className="mt-3">
              <label className="contact-label" htmlFor="contact-form-email">
                <span className="text-md">Email</span>
                <input
                  id="contact-form-email"
                  className="contact-input text-md"
                  type="email"
                  name="user_email"
                  value={email}
                  onChange={(e) => dispatch({ type: 'email', value: e.target.value })}
                  required
                />
              </label>
            </Row>
            <Row className="mt-3">
              <label className="contact-label" htmlFor="contact-form-message">
                <span className="text-md">Message</span>
                <textarea
                  name="message"
                  id="contact-form-message"
                  className="contact-input text-md"
                  type="text"
                  value={message}
                  onChange={(e) => dispatch({ type: 'message', value: e.target.value })}
                  rows="8"
                  placeholder="Type your message..."
                  required
                />
              </label>
            </Row>
            <Row className="mt-4">
              <div className="contactFormMsg">
                {showFormErr ? <p className="sm:mr-4 text-red-400">Please fill in all three input boxes to send a message</p> : null}
                <button className="btn btn-primary contact-form-btn" type="submit">
                  Send
                </button>
              </div>
            </Row>
          </Container>
        </form>
      ) : (
        <div className="contactCaptcha d-flex justify-content-center">
          <ReCAPTCHA
            sitekey="6LcJGIEpAAAAACIYeOmJ0Wnew0Y6Qj7bvvfujpUa"
            onChange={sendEmail}
          />
        </div>
      )}
    </div>
  ) : (
    <div className="submittedFormWrap">
      <h3 className="submittedFormTitle">{formSubmitted.title}</h3>
      <p className="submittedFormContent">{formSubmitted.paragraph}</p>
    </div>
  );
}