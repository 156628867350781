import React from "react";
import Balancer from "react-wrap-balancer";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faBehance } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


export default function Inner404() {
  return (
    <section id="hero404" className="hero-section">
      <div className="hero-wrap">
        <Container>
          <Row>
            <div className="col-12 order-2 col-lg-6 order-lg-1 hero-col">
              <div className="hero-container">
                <div className="hero-content">
                    <p className="section-title">Oh No!</p>
                    <h1 className="title">
                      <span className="accent-txt-primary"><Balancer>404 Page Not Found.</Balancer></span>{ " " }
                    </h1>


                    <p className="description inner-404">
                      [psst...] I think you broke my site, thanks. ☺︎<br/>{ " " }
                      In the meantime, checkout my socials:{ " " }
                    </p>

                  <div className="col-12 hero-social">
                    <a href="https://linkedin.com/in/bekkyrage/" className="btn btn-primary" target="_blank">
                      <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                    </a>
                    <a href="https://behance.net/bekkyrage" className="btn btn-primary" target="_blank">
                      <FontAwesomeIcon icon={faBehance} /> Behance
                    </a>
                    <a href="mailto:bekkyrage@gmail.com" className="btn btn-primary" target="_blank">
                      <FontAwesomeIcon icon={faEnvelope} /> Email
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 order-1 col-lg-6 order-lg-2">
              <div className="hero-img-wrap">
                <div className="hero-img">
                  <img src="./img/hero_img.png" alt="Hero Section" />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}