import React from "react";
import Balancer from "react-wrap-balancer";

export default function About() {
  return (
    <section id="About" className="about-section">
      <div className="about-img">
        <img src="./img/about.png" alt="About Bekky Rage" />
      </div>
      <div className="section-header-wrapper about-section-header">
        <div className="section-header-container">
          <p className="sub-title">Bio</p>
          <h2 className="section-heading">About</h2>

        </div>
        <div className="about-section-body">
          <p className="description">
            I'm a fangirl of all things technology and started dissecting and building websites when I was in 4th grade as a hobby. My websites were all the rage back then (i.e. blinking links and logos, animated blinking text, mouse trails, and busy backgrounds with large images-also rainbows). I'll let you have a facepalm moment at my 4th grade self.
          </p>
          <p className="description">
            I've taken my hobby and turned it into a career, perfecting my craft and skills at Virginia Commonwealth University. I've studied both fine arts and design and have combined the two into my career path.
          </p>
          <p className="description">
            Now, I'm a skilled digital graphic designer with a passion for combining art and technology together. Illustration, story boarding, thinking outside of the box, finding creative solutions, all come naturally for me.
          </p>
        </div>
      </div>
    </section>
  )
}