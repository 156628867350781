import logo from '../../logo.svg';
import '../../styles/App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import Navbar from '../../theme/global/navbar';
import Home from '../../theme/pages';
// import Footer from '../../theme/global/footer';
import _404 from '../../theme/pages/_404';


function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="*" element={<_404 />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
