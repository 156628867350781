import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import nav from "../../data/nav.json";
import pdfResume from "../../assets/2024_Bekky_Rage_Resume_online.pdf";

function SubNavbar() {
  const [navActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu();
    }
  }, []);

  return (
    <nav className={`navbar ${navActive ? "active" : ""}`}>
      <div className="mainLogoWrapper">
        <div className="mainLogo">
          <a className="linkHome" href="/">
            <img src="./img/pink-bow-white-center.png" alt="Logo" />
            <code>
              {`
                {bekky.ambient.rage}
              `}
            </code>
          </a>

        </div>
      </div>
      <a className={`nav_hamburger ${navActive ? "active" : ""}`} onClick={toggleNav}>
        <span className="nav_hamburger_line"></span>
        <span className="nav_hamburger_line"></span>
        <span className="nav_hamburger_line"></span>
      </a>

      <div className={`navbar-items ${navActive ? "active" : ""}`}>
        <ul>
          {nav?.map((item, index) =>
            <li key={index}>
              <a onClick={closeMenu}
                activeClass="navbar-active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to={item.path}
                href={'/'}
                className="navbar-content"
              >
                {item.page}
              </a>
            </li>
          )}
        </ul>
      </div>

      <a href={pdfResume} download="2024_Bekky_Rage_Resume.pdf" className="btn btn-outline-primary">Download Resume</a>

    </nav>
  );
}

export default SubNavbar;