import React, { useState, useEffect } from 'react';
import Balancer from "react-wrap-balancer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import work from "../../data/work.json";


import { Container, Row, Col, Button, Modal } from "react-bootstrap";


export default function Portfolio() {
  const [showModal, setShowModal] = useState(Array(work.length).fill(false));

  const handleClose = (index) => setShowModal((prev) => prev.map((_, i) => (i === index ? false : _)));
  const handleShow = (index) => setShowModal((prev) => prev.map((_, i) => (i === index ? true : _)));


  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(3);

  const showMoreItems = () => {
    setVisible(preValue => preValue + 3);
  };

  const showLessItems = () => {
    setVisible(preValue => Math.max(preValue - 3, 3)); // Ensure minimum visibility of 3 items
  };

  useEffect(() => {
    setItems(work.slice(0, visible));
  }, [visible]);

  const moreItemsAvailable = visible < work.length;

  return (
    <section className="portfolio-section" id="Portfolio">

      <div className="section-header-wrapper">
        <div className="section-header-container">
          <p className="sub-title">Recent Work</p>
          <h2 className="section-heading">Portfolio</h2>
        </div>
      </div>

      <div className="portfolio-section-container">
        {items.slice(0, visible).map((item, index) => (
          <div key={index} className="portfolio-card">
            <>
              <Button variant="primary" onClick={() => handleShow(index)} className="portfolio-card">
                <div className="portfolio-section-card">
                  <div className="portfolio-section-link-wrap">
                    <div className="portfolio-snapshot">
                      <div className="browserTop">
                        <div className="urlWrap">
                          <div className="projectHeadingContainer">
                            <div className="icon-ssl icon-embed"><FontAwesomeIcon icon={faLock} /></div>
                            <div className="projectHeading">
                              {item.url}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="portfolio-section-img">
                        <img src={item.src} alt={item.title} />
                      </div>
                    </div>

                    <div className="portfolio-section-card-content">
                      <div className="work">


                        {item.intro && (
                          <div>
                            <h3 className="portfolio-section-title">
                              {item.title}
                            </h3>
                            <p className="portfolio-intro"><Balancer>{item.intro}</Balancer></p>
                          </div>

                        )}

                        <div className={`portfolio-link ${item.title && item.intro ? 'with-margin' : 'without-margin'}`}>
                          <FontAwesomeIcon icon={faLaptopCode} />View Project
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Button>

              <Modal show={showModal[index]} onHide={() => handleClose(index)} centered className="portfolioWork">
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3 className="portfolio-section-title">
                      {item.url}
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="portfolio-section-img">
                  <img src={item.src} alt="Placeholder" />
                </Modal.Body>
                <Modal.Body className="portfolio-section-card-content" >
                  <Modal.Body className="work">

                    <Container className="modalBody">
                      <Row>
                        <Col xs={12} md={6} lg={3}>
                          <div className="subInfo">
                            {item.position && item.position.trim() !== "" && (
                              <div>
                                <div className="title"><h4>Position:</h4></div>
                                {item.position}
                              </div>
                            )}

                            {item.frameworks && item.frameworks.some(framework => framework.trim() !== "") && (
                              <div>
                                <div className="title"><h4>Technologies:</h4></div>
                                <>
                                  {item.frameworks.map((framework, i) => (
                                    framework.trim() !== "" && (
                                      <span key={i}>#{framework} </span>
                                    )
                                  ))}
                                </>
                              </div>
                            )}

                            {item.workWith && item.workWith.some(workWithItem => workWithItem.name || workWithItem.url || workWithItem.title) && (
                              <div>
                                <div className="title"><h4>Worked With:</h4></div>
                                <ul>
                                  {item.workWith.map((workWithItem, i) => (
                                    (workWithItem.name || workWithItem.url || workWithItem.title) && (
                                      <li key={i}>
                                        {workWithItem.url ? (
                                          <a href={workWithItem.url}>{workWithItem.name}</a>
                                        ) : (
                                          workWithItem.name
                                        )}

                                        {workWithItem.title && (
                                          <span>, {workWithItem.title}</span>
                                        )}
                                      </li>
                                    )
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>

                        </Col>
                        <Col xs={12} md={6} lg={8}>
                          <div className="mainInfo">
                            <h1 className="portfolio-section-title">
                              {item.title}
                            </h1>

                            {item.descriptions && item.descriptions.some(desc => desc.trim() !== "") && (
                              <div className="portfolio-description">
                                <Balancer>
                                  <>
                                    {item.descriptions.map((description, i) => (
                                      description.trim() !== "" && (
                                        <p key={i}>{description}</p>
                                      )
                                    ))}
                                  </>
                                </Balancer>
                              </div>
                            )}

                            <div>
                              <a href={`https://www.${item.url}`} className="btn btn-primary" target="_blank" ><FontAwesomeIcon icon={faGlobe} /> Visit Live</a>
                            </div>
                          </div>

                        </Col>
                      </Row>
                      <Row>
                        {item.supportImages && item.supportImages.some(image => image.trim() !== "") && (
                          <div className="support">
                            <>
                              {item.supportImages.map((supportImage, i) => (
                                supportImage.trim() !== "" && (
                                  <div className="support-img" key={i}><img src={supportImage} alt={item.title} /></div>
                                )
                              ))}
                            </>
                          </div>
                        )}
                      </Row>
                    </Container>

                  </Modal.Body>
                </Modal.Body>
              </Modal>
            </>
          </div>
        ))}

      </div>
      <Container>
        <Row>
          <div className="col-12 paginationWorkWrapper">
            <div className="paginationWork">
              <div className="d-flex justify-content-evenly">
                {moreItemsAvailable && (
                  <button className="btn btn-primary viewMorePortfolio" onClick={showMoreItems}> Load More Work</button>
                )}
                {visible > 3 && (
                  <button className="btn btn-primary viewLessPortfolio" onClick={showLessItems}> Load Less Work</button>
                )}
              </div>

            </div>

          </div>
        </Row>
      </Container>


    </section>
  )
}