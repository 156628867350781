import Navbar from "../global/navbar";
import Hero from "../components/hero";
// import Skills from "../components/skills";
import About from  "../components/about";
import Portfolio from "../components/portfolio";
import Contact from "../components/contact";
import Footer from "../global/footer";


export default function Home () {
  return(
    <>
      <Navbar />
      <Hero />
      <Portfolio />
      {/* <Skills /> */}
      <About />
      <Contact />

      <Footer />
    </>
  )
}